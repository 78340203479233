import React from 'react';
import './App.css';

function App() {

  return (
    <>


      <div className="flex h-screen flex-col">
        <h1 className="text-green-600 text-5xl font-bold text-center">
          Website Under Construction
        </h1>
        <div className="flex flex-1 items-center justify-center p-2 text-4xl">
          <div className="bg-yellow-400 p-6">
            <img src="./websites-why-you-should-never-use-under-construction-pages.jpg" />
          </div>
        </div>
      </div>
    </>
  )
}

export default App;
